.container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.euvic {
  width: 50%;
  position: relative;
  top: 0;
  transition: top ease 0.5s;
  z-index: 1;
}
.euvic:hover ~ .the-good-people {
  opacity: 1;
  top: 53vh;
}
.euvic:hover {
  top: -50px;
}

.the-good-people {
  width: 25%;
  position: absolute;
  top: 51vh;
  opacity: 0;
  transition: all ease 0.8s;
}
