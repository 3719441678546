.App {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}
.logo-container {
  width: 60%;
  height: 100%;
  background-color: #f5f7fa;
  display: flex;
  justify-content: center;
  align-items: center;
}
.registration-form {
  height: 100%;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1200px) {
  .logo-container {
    display: none;
  }
  .registration-form {
    height: 100%;
    width: 100%;
  }
}
